import dayjs from 'dayjs';

import { VenomNetworkApi } from 'api';
import { CONFIG } from 'config';
import { makeAutoObservable, runInAction } from 'mobx';
import { StageModel, TaskModel } from 'models';
import { UserStore } from 'stores';

export class MainPageStore {
  constructor(
    private userStore: UserStore,
    private venomNetworkApi: VenomNetworkApi
  ) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private _initialized = false;

  private _stages: StageModel[] = [];
  private _tasks: TaskModel[] = [];

  private _currentSelectedStage: StageModel | null = null;
  private _currentSelectedStageTasks: TaskModel[] = [];

  get initialized() {
    return this._initialized;
  }

  get stages() {
    return this._stages;
  }

  get claimStage() {
    return new StageModel({
      id: -10,
      description: '',
      startDate: 0,
      endDate: 0,
      stage: -1,
      stakingCoefficients: [],
    });
  }

  get currentSelectedStage() {
    return this._currentSelectedStage;
  }

  get currentSelectedStageTasks() {
    return this._currentSelectedStageTasks;
  }

  get canClaimRewards() {
    return this._stages.every((x) => x.status === 'completed');
  }

  async init() {
    const seasonInfo = await this.venomNetworkApi.info.getInfo({
      season: CONFIG.season.current,
    });

    const stages = Array.from(
      { length: CONFIG.season.totalStages },
      (_, i) => i + 1
    ).map((stageNumber) => {
      const stg = seasonInfo.data.stages.find((x) => x.stage === stageNumber);

      if (!stg) {
        return new StageModel({
          id: 0,
          description: '',
          startDate: dayjs().add(1, 'year').unix(),
          endDate: dayjs().add(2, 'year').unix(),
          stage: stageNumber,
          stakingCoefficients: [],
        });
      }

      return new StageModel(stg);
    });

    const currentSelectedStage =
      stages.find((x) => x.status === 'live') ?? this.claimStage;

    const tasks = seasonInfo.data.tasks
      .filter((x) => x.isShown)
      .filter((x) => x.isSocial)
      .map((task) => new TaskModel(task));

    runInAction(() => {
      this._stages = stages;
      this._initialized = true;
      this._tasks = tasks;

      this.setCurrentStage(currentSelectedStage);
    });
  }

  async refresh() {
    return this.init();
  }

  setCurrentStage(stage: StageModel) {
    if (stage.status === 'upcoming') {
      return;
    }

    const currentStageTasks = this._tasks.filter((x) => x.stageId === stage.id);

    runInAction(() => {
      this._currentSelectedStage = stage;
      this._currentSelectedStageTasks = currentStageTasks;
    });
  }
}
