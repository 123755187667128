import { makeAutoObservable } from 'mobx';
import { AppRoutes } from 'routes';
import { AuthStore, TelegramStore, UserStore } from 'stores';

export class GoPageStore {
  constructor(
    private userStore: UserStore,
    private telegramStore: TelegramStore,
    private authStore: AuthStore
  ) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get showConnectWallet() {
    return !this.userStore.currentUser?.wallet;
  }

  connectWallet() {
    if (typeof window === 'undefined' || !this.authStore.accessToken) {
      return;
    }

    const link = `${window.location.origin}${AppRoutes.connectWallet.path}?token=${this.authStore.accessToken}`;

    this.telegramStore.openLinkViaVenomWallet(link);
  }
}
