export const stageConfig = {
  api: {
    baseUrl: 'https://vn-indexer-stage.bf.works',
  },
  season: {
    current: 3,
    totalStages: 6,
  },
  venomNetwork: {
    url: 'https://venom.network',
    claim: 'https://venom.network/claim/season-3',
  },
  telegramBotApp: {
    url: 'https://t.me/VenomNetworkAppTestBot/main',
    deepLink: 'tg://resolve?domain=VenomNetworkAppTestBot&appname=main',
  },
};
