import styles from './main-page-stage-info.module.css';

import claimRewardImg from 'images/main/claim-logo.webp';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useMainPageStore } from '../main-page-store-provider';
import { StageStatusBadge } from 'components/common';
import { useCallback, useMemo } from 'react';
import { StageTimer } from 'components/common/stage-timer/stage-timer';
import { useBaseStores } from 'providers/base-stores-provider';
import { CONFIG } from 'config';

export const MainPageStageInfo = observer(() => {
  const intl = useIntl();
  const mainPageStore = useMainPageStore();
  const { telegramStore } = useBaseStores();

  const currentStage = useMemo(
    () => mainPageStore.currentSelectedStage,
    [mainPageStore.currentSelectedStage]
  );

  const onCurrentStageTimerFinished = useCallback(() => {
    mainPageStore.refresh();
  }, [mainPageStore]);

  const onClaimRewardClick = useCallback(() => {
    telegramStore.openLinkViaVenomWallet(CONFIG.venomNetwork.claim);
  }, [telegramStore]);

  if (!currentStage) {
    return null;
  }

  if (currentStage.id === mainPageStore.claimStage.id) {
    return (
      <div className={styles.main_outer}>
        <div className={styles.main_inner}>
          <button className={styles.claim_reward} onClick={onClaimRewardClick}>
            <img src={claimRewardImg} width={580} height={624} alt='' />

            <span>
              {intl.formatMessage(
                {
                  id: 'page.main.stages.claim.text',
                  defaultMessage:
                    'If you want to get rewards, please go to {venomNetwork}',
                },
                {
                  venomNetwork: (
                    <span className={styles.venom_network}>venom.network</span>
                  ),
                }
              )}
            </span>

            <button>
              {intl.formatMessage({
                id: 'page.main.stages.claim.button',
                defaultMessage: 'Go to Claim',
              })}
            </button>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main_outer}>
      <div className={styles.main_inner}>
        <div className={styles.header}>
          <div className={styles.title}>
            {intl.formatMessage(
              {
                id: 'page.main_page.stage_title',
                defaultMessage: 'Stage {number}',
              },
              { number: currentStage.stageIndex }
            )}
          </div>

          <StageStatusBadge status={currentStage.status} />
        </div>

        {currentStage.status === 'live' && (
          <StageTimer
            finishDate={currentStage.endDate}
            onFinish={onCurrentStageTimerFinished}
          />
        )}

        {!!currentStage.descriptionHtml && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: currentStage.descriptionHtml,
            }}
          ></div>
        )}
      </div>
    </div>
  );
});
